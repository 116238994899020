module.exports={
    "PLATFORM_ID": "lachampagne",
    "API_URL": "https://rando.destinationchampagne.fr",
    "BACKOFFICE_URL": "https://geotrek-champagne-ardenne.makina-corpus.net",
    "DEFAULT_META_DESCRIPTION": "Le site des balades et randonnées en Ardenne et en Champagne. Consultez les circuits sur fonds de carte IGN, téléchargez les traces GPS ou imprimez votre itinéraire.",
    "ENABLE_HTML_MODE": true,
    "LOGO_FILE": "LA_CHAMP_Logo_Destination_CMJN_HD.png",
    "SHOW_HOME": false,
    "SHOW_FOOTER": true,
    "FOOTER_TEMPLATE_FILE": "custom-footer.html",
    "ENABLE_DISTRICTS_FILTERING": false,
    "ENABLE_STRUCTURE_FILTERING": false,
    "NEAR_ELEMENTS_CATEGORIES": true,
    "DEFAULT_ACTIVE_CATEGORIES": ["T1", "T2", "T4"],
    "TREKS_TO_GEOJSON_ZOOM_LEVEL": 7,
    "RULES_FLAT_PAGES_ID": "9",
    "DEFAULT_LANGUAGE": "fr",
    "ENABLED_LANGUAGES": ["fr", "en", "nl", "de"],
    "FILTER_BY_VIEWPORT_DEFAULT": true,
    "UPDATE_MAP_ON_FILTER": true,
    "GOOGLE_ANALYTICS_ID": "UA-58638778-4",
    "FACEBOOK_APP_ID": "310265243451731",
    "PERMANENT_TILELAYERS": [
        {
            "LAYER_URL": "//{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
            "OPTIONS": {
                "id": "opentopomap",
                "icon": "/custom/images/opentopomap.png",
                "attribution": "Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)"
            }
        },
        {
            "LAYER_URL": "//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            "OPTIONS": {
                "id": "osm_belgie",
                "attribution": "&copy; OpenStreetMap",
                "minZoom": 13
            }
        }
    ],
    "ORTHOPHOTO_TILELAYERS": {
        "LAYER_URL": "//data.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&STYLE=normal&FORMAT=image/jpeg&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        "OPTIONS": {
            "id": "satellite",
            "attribution": "&copy; Orthophotos - Carte © IGN/Geoportail"
        }
    },
    "LEAFLET_CONF": {
        "CENTER_LATITUDE": 48.3705824,
        "CENTER_LONGITUDE": 4.3128168,
        "DEFAULT_ZOOM": 10,
        "DEFAULT_MIN_ZOOM": 7,
        "DEFAULT_MAX_ZOOM": 17
    }
}
